@import "../../Styles/breakpoints.scss";
.project-container {
  display: flex;
  flex-direction: column;
  gap: 3.12rem;

  .capella-title {
    color: var(--capella-title);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .project-details {
    .info-label {
      color: var(--info-label);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }

    .info-value {
      color: var(--info-value);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-transform: capitalize;
    }
    .project-timeline {
      margin-top: 2.37rem;
      gap: 2.56rem;
    }
  }
  .project-description {
    color: var(--project-description);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
  .capella-project-link {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    display: flex;
    align-items: flex-end;
    gap: 0.1rem;

    .project-link {
      text-decoration: none;
      line-height: normal;
      color: #DF1A26;

      // &:hover {
      //   text-decoration: underline;
      // }
    }
    
    .project-link-icon {
      width: 1.125rem;
      height: 1.125rem;
    }
  }
}

.projects-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 6rem;

  @include md-viewport {
    grid-template-columns: 1fr 2fr;
  }
}

.mobile-capella-image-grid {
  display: grid;
  grid-template-rows: auto auto auto;
  gap: 2rem;

  @include md-viewport {
    display: none;
  }

  .imgs-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .image1 {
    border-radius: 1.25rem;
    background: #FFE1E4;
    & img {
      width: 100%;
      height: auto;
    }
  }
  .image2 {
    border-radius: 1.25rem;
    background: #B62025;
    & img {
      width: 100%;
      height: auto;
    }
  }
  .image3 {
    border-radius: 1.25rem;
    background: #94B7BB;
    & img {
      width: 100%;
      height: auto;
    }
  }
}

.capella-image-grid {
  display: none;

  @include md-viewport {
    display: grid;
    grid-template-columns: 1fr 2fr; /* 1 fraction of the space for left boxes, 3 fractions for the right box */
    grid-template-rows: 1fr 3fr;
    gap: 2.25rem;
  }

  .imgs-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  // .imgs-container:nth-child(3) {
  //   grid-column: 1 / -1;
  // }

  .image1 {
    grid-row: 1 / 2; /* Span from the first to the second row line */
    grid-column: 1 / 2;
    border-radius: 1.25rem;
    background: #FFE1E4;
    background-image: url("../../assets/images/capella/image1.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .image2 {
    grid-row: 2 / 3; /* Span from the second to the third row line */
    grid-column: 1 / 2; 
    border-radius: 1.25rem;
    background: #B62025;
    background-image: url("../../assets/images/capella/image2.png");
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  .image3 {
    grid-row: 1 / 3; /* Span from the first to the third row line */
    grid-column: 2 / 3;
    border-radius: 1.25rem;
    background: #94B7BB;
    background-image: url("../../assets/images/capella/image3.png");
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
  }
}
