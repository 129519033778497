@import "../../Styles/breakpoints.scss";

.footer {
  background-image: url("../../assets/images/footer.png");
  background-size: cover;

  .footer-section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include md-viewport {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
  }

  .footer-title {
    color: #fff;
    font-family: Porlane;
    font-size: 6.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    @include md-viewport {
        font-size: 9.375rem;
    }
  }
}
.footer-header {
    padding: 0;
}