.separator {
  display: flex;
  align-items: center;
  gap: 4.5rem;
  .text-m {
    font-family: "Porlane";
    font-size: 8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    color: var(--separator-text);
    text-wrap: nowrap;
  }
  .line {
    width: 100%;
    height: 0.0725rem;
    background-color: var(--separator-text);
  }
}
