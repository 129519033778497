.links-container {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  justify-content: center;

  .links-padding {
    margin-left: 1.5rem;
  }

  a {
    color: white;
    text-decoration: none;
    &:visited {
      color: white;
    }
  }

  .icon-hover-effect {
    position: relative;
    width: 30px; /* Example size; adjust as needed */
    height: 30px; /* Example size; adjust as needed */
  }

  .icon-link {
    display: block;
    width: 100%;
    height: 100%;
  }

  .icon-link img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease;
  }

  .img-grayscale {
    opacity: 1;
  }

  .img-color {
    opacity: 0;
  }

  // .icon-hover-effect:hover .img-grayscale {
  //   opacity: 0;
  // }

  // .icon-hover-effect:hover .img-color {
  //   opacity: 1;
  // }
}
