@import "../../Styles/breakpoints.scss";
.project-container {
  display: flex;
  flex-direction: column;
  gap: 3.12rem;

  .about-title {
    color: var(--about-title);
    font-family: "Helvetica Neue";
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .about-description {
    color: var(--about-description);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
  }
}

.projects-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 6rem;

  @include md-viewport {
    grid-template-columns: 1fr 2fr;
  }
}

.mobile-about-image-grid {
  display: grid;
  grid-template-rows: auto auto auto;
  gap: 2rem;

  @include md-viewport {
    display: none;
  }

  .imgs-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .image1 {
    border-radius: 1.25rem;
    background: #ffe1e4;
    & img {
      width: 100%;
      height: auto;
    }
  }
  .image2 {
    border-radius: 1.25rem;
    background: #b62025;
    & img {
      width: 100%;
      height: auto;
    }
  }
  .image3 {
    border-radius: 1.25rem;
    background: #94b7bb;
    & img {
      width: 100%;
      height: auto;
      border-radius: 1.25rem;
    }
  }
  .image4 {
    border-radius: 1.25rem;
    background: #94b7bb;
    & img {
      width: 100%;
      height: auto;
    }
  }
  .image5 {
    border-radius: 1.25rem;
    background: #94b7bb;
    & img {
      width: 100%;
      height: auto;
    }
  }
}

.about-image-grid {
  display: none;

  @include md-viewport {
    display: grid;
    grid-template-columns: repeat(
      5,
      1fr
    ); /* Creates 4 columns of equal width */
    grid-template-rows: 1fr 2fr 1fr;
    gap: 2.25rem;
  }

  .imgs-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  // .imgs-container:nth-child(3) {
  //   grid-column: 1 / -1;
  // }

  .image1 {
    grid-area: 1 / 1 / 2 / 3;
    border-radius: 1.25rem;
    background: #ffe1e4;
    background-image: url("../../assets/images/about/map.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .image2 {
    grid-area: 1 / 3 / 2 / 6;
    border-radius: 1.25rem;
    background: #b62025;
    background-image: url("../../assets/images/about/event.png");
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  .gallery {
    grid-area: 2 / 1 / 3 / 6;
    object-fit: cover;
  }
  .image3 {
    grid-area: 3 / 1 / 4 / 4;
    border-radius: 1.25rem;
    background: #94b7bb;
    background-image: url("../../assets/images/about/watch.png");
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  .gallery-img {
    width: 100%;
    height: 600px;
    border-radius: 1.25rem;
    object-fit: cover;
  }
  .image4 {
    grid-area: 3 / 4 / 4 / 6;
    border-radius: 1.25rem;
    background: #94b7bb;
    background-image: url("../../assets/images/about/racing.png");
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }
}
