// .switch {
//   width: 60px;
//   height: 30px;
//   border-radius: 1.25rem;
//   position: relative;
//   cursor: pointer;
//   transition: background 0.5s ease-in-out;
//   display: flex;
//   align-items: center;

//   &.on {
//     background: linear-gradient(90deg, #ff9735 0%, #ffc700 100%);
//   }

//   &.off {
//     background: linear-gradient(90deg, #0c32ff 0%, #2a00d0 100%);
//   }

//   &::before {
//     position: absolute;
//     content: "";
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     background-image: linear-gradient(
//       to bottom,
//       hsl(344, 100%, 50%),
//       hsl(31, 100%, 40%)
//     );
//     z-index: -1;
//     transition: opacity 0.5s linear;
//     opacity: 0;
//   }

//   &:hover::before {
//     opacity: 1;
//   }

//   .switch-circle {
//     width: 26px;
//     height: 26px;
//     border-radius: 50%;
//     background-size: cover;
//     background-position: center;
//     position: absolute;
//     top: 2px;
//     left: 2px;
//     transition: left 0.5s ease-in-out, transform 0.5s ease-in-out;

//     &.on {
//       left: 32px;
//       transform: rotate(-10deg);
//     }

//     &.off {
//       left: 2px;
//       transform: rotate(0deg);
//     }
//   }
// }

.switch {
    width: 60px;
    height: 30px;
    border-radius: 1.25rem;
    position: relative;
    // cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 1.25rem;
        transition: opacity 0.3s ease-in-out;
        z-index: 0;
    }

    &:before {
        background: linear-gradient(90deg, #ff9735 0%, #ffc700 100%); /* Gradient for 'off' state */
        opacity: 1;
    }

    &:after {
        background: linear-gradient(90deg, #0C32FF 0%, #2A00D0 100%); /* Gradient for 'on' state */
        opacity: 0;
    }

    &.on:before {
        opacity: 0;
    }

    &.on:after {
        opacity: 1;
    }

    .switch-circle {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 2px;
        left: 2px;
        transition: left 0.3s ease-in-out, transform 0.5s ease-in-out;
        z-index: 1; /* Ensure the circle is above the gradients */

        &.on {
            left: 32px;
            transform: rotate(-10deg);
        }

        &.off {
            left: 2px;
            transform: rotate(0deg);
        }
    }
}
