@mixin sm-viewport {
    @media (min-width: 48em) {
      @content;
    }
  }
  @mixin md-viewport {
    @media (min-width: 62em) {
      @content;
    }
  }
  @mixin lg-viewport {
    @media (min-width: 75em) {
      @content;
    }
  }