@import "../../Styles/breakpoints.scss";

.other-projects {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.75rem;
  @include md-viewport {
    grid-template-columns: 1fr 1fr;
    gap: 5.75rem 2.75rem;
  }

  @include lg-viewport {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .bgry-link {
    color: var(--bgry-link);
  }

  .weather-link {
    color: #1664c0;
  }

  .smart-link {
    color: #e87204;
  }

  .maps-link {
    color: #e87204;
  }

  .movies-link {
    color: #ff6476;
  }

  .project-card {
    .project-card-title {
      margin-top: 2.38rem;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      color: var(--card-title);
    }

    .project-card-description {
      margin-top: 1rem;
      color: var(--card-description);
      font-family: "Helvetica Neue";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }

    .project-card-link {
      margin-top: 0.8rem;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      display: flex;
      align-items: flex-end;
      gap: 0.1rem;

      .project-link {
        text-decoration: none;
        line-height: normal;

        // &:hover {
        //   text-decoration: underline;
        // }
      }

      .project-link-icon {
        width: 1.125rem;
        height: 1.125rem;
      }
    }
    .project-image {
      width: 100%;
      border-radius: 1.25rem;
      // z-index: 3;
    }
  }
}
