@import "./breakpoints.scss";

.section {
  max-width: 1280px;
  margin: 0 auto;
  // line-height: var(--mono-line-height);
  // font-size: 1.1rem;
  padding: 3.3em 1rem 3.3em 1rem;
  background-size: contain;
  background-repeat: repeat-y;
  padding-bottom: 3em;
}
.d-flex {
  display: flex;
}

// Base font sizes
h1 {
  font-size: 1.5rem; // Default font size for mobile
  @include sm-viewport {
    font-size: 1.75rem; // Adjust for small devices
  }
  @include md-viewport {
    font-size: 2rem; // Adjust for medium devices
  }
  @include lg-viewport {
    font-size: 2.5rem; // Adjust for large devices
  }
}

.description {
  font-size: 1rem; // Default font size for mobile
  @include sm-viewport {
    font-size: 1.125rem; // Adjust for small devices
  }
  @include md-viewport {
    font-size: 1.25rem; // Adjust for medium devices
  }
  @include lg-viewport {
    font-size: 1.5rem; // Adjust for large devices
  }
}

.content {
  font-size: 0.875rem;
  line-height: 110%;
  @include sm-viewport {
    font-size: 1rem;
  }
  @include md-viewport {
    font-size: 1rem;
  }
  @include lg-viewport {
    font-size: 1.125rem;
  }
}

.content-spacer {
  padding-bottom: 0.5rem;
  @include sm-viewport {
    padding-bottom: 0.6rem;
  }
  @include md-viewport {
    padding-bottom: 0.8rem;
  }
  @include lg-viewport {
    padding-bottom: 1.5rem;
  }
}

.content-spacer-first {
  padding-top: 0.5rem;
  @include sm-viewport {
    padding-top: 0.6rem;
  }
  @include md-viewport {
    padding-top: 0.8rem;
  }
  @include lg-viewport {
    padding-top: 1.5rem;
  }
}

.half-width {
  width: 100%;

  @include lg-viewport {
    width: 66%; // Adjust for large devices
  }
}

.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 2;
}

.resp-flex {
  display: flex;
  flex-direction: column;
  @include lg-viewport {
    flex-direction: row;
  }
}

.text-center{
  text-align: center;
}
