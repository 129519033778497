@import "../../Styles/breakpoints.scss";
.header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: none;
  font-family: "Helvetica Neue";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: var(--header-text);

  @include md-viewport {
    display: flex;
    justify-content: space-between;
  }

  .navigation {
    display: none;
    gap: 1rem;
    font-style: normal;
    font-weight: 300;
    align-items: center;

    .nav-resume {
      a {
        text-decoration: none;
        color: var(--header-text);
      }
      
    }
    @include md-viewport {
      display: flex;
    }
  }
}
