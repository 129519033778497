@import "./Styles/main.scss";

:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --header-text: #4f4f4f;
  --marquee-text: #d6d6d6;
  --new-challenges: #333;
  --landing-text: #fff;
  --separator-text: #d6d6d6;
  --bhhs-title: #84335c;
  --capella-title: #a6221e;
  --info-label: #afafaf;
  --info-value: #232323;
  --project-description: #646464;
  --card-title: #262626;
  --card-description: #646464;
  --bgry-link: #000;
  --about-title: #303030;
  --about-description: #646464;
  --selection-background: #000;
  --selection-color: #fff;
  --cursor-hover: rgba(0, 0, 0);
}

[data-theme="dark"] {
  --background-color: #1e1e1e;
  --text-color: #f2f2f2;
  --header-text: #f2f2f2;
  --marquee-text: #434343;
  --new-challenges: #f2f2f2;
  --landing-text: #8a8a8a;
  --separator-text: #454545;
  --bhhs-title: #dba6c1;
  --capella-title: #fe5b65;
  --info-label: #afafaf;
  --info-value: #fff;
  --project-description: #d8d8d8;
  --card-title: #fff;
  --card-description: #b3b3b3;
  --bgry-link: #fff;
  --about-title: #efefef;
  --about-description: #bbb;
  --selection-background: #fff;
  --selection-color: #000;
  --cursor-hover: rgba(255, 255, 255);
}

::selection {
  background: var(--selection-background);
  color: var(--selection-color);
}

html {
  font-family: "Helvetica Neue";
  height: 100%;
  cursor: none;

  font-size: 16px; // Default base font size for mobile devices

  @include sm-viewport {
    font-size: 1rem; // 16px
  }

  @include md-viewport {
    font-size: 1.125rem; // 18px
  }

  @include lg-viewport {
    font-size: 1.125rem; // 20px
  }
}
body {
  font-family: "Helvetica Neue";
  height: 100%;

  background-color: var(--background-color);
  color: var(--text-color);
  transition: color 0.3s;
  position: relative;
  overflow-x: hidden;
  cursor: none;

  font-size: 16px; // Default base font size for mobile devices

  @include sm-viewport {
    font-size: 1rem; // 16px
  }

  @include md-viewport {
    font-size: 1.125rem; // 18px
  }

  @include lg-viewport {
    font-size: 1.125rem; // 20px
  }
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  z-index: -1;
  transition: background-color 0.3s ease-in-out;
}

body::before {
  background-color: var(--background-color);
}

body::after {
  background-color: var(--background-color);
}

.custom-cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: var(--cursor-hover);
  border-radius: 50%;
  pointer-events: none;
  transition: transform 0.2s ease, background 0.2s ease, border-radius 0.2s ease;
  z-index: 10000;
}

.text-hover {
  // transform: rotate(90deg) scale(0.5, 2);
  transform: rotateX(180deg) scale(0.1, 1.2);
  border-radius: 0%;
  background: rgba(0, 0, 0, 0.8);
}

.link-hover {
  transform: scale(2);
  background: rgba(0, 0, 0);
}

/* Ensure the text cursor class is applied correctly */
[data-theme="dark"] .text-hover {
  background: rgba(255, 255, 255);
}

[data-theme="dark"] .link-hover {
  background: rgba(255, 255, 255);
}

.image-container {
  position: relative;
  // overflow: hidden;
}

.parallax-image, .parallax-nav {
  transition: transform 0.1s ease-out;
}
