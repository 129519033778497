.new-challenges {
    display: flex;
    align-items: center;
    font-family: "Helvetica Neue";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    color: var(--new-challenges);
    gap: 0.6875rem;
    justify-content: center;

    .challenge-icon {
        width: 1.5rem;
    }
}

.section-container {
    opacity: 0;
}