@import "../../Styles/breakpoints.scss";
.landing {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 1.5rem;
  height: 100%;
  font-family: 'Space Mono';

  @include md-viewport {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: none;
  }
  .photo {
    border-radius: 1.25rem;
    background-image: url("../../assets/images/profile_color.png"); // Correct path as per your project structure
    background-size: cover; // Covers the entire div without repeating
    background-position: center;
    filter: grayscale(100%);
    transition: filter 0.3s ease-in-out;

    &:hover {
      filter: grayscale(0%);
    }
  }
  .intro {
    padding: 3.125rem 4.1875rem 2.1875rem 2.875rem;
    border-radius: 1.25rem;
    background: #232323;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    color: var(--landing-text);

    .content {
      margin-bottom: 18.25rem;
      line-height: 150%;
    }

    .rishi {
      color: #b780ff;
      line-height: 121.305%;
      margin-bottom: 1rem;
    }
  }
}
