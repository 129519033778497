//Montserrat
@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf")
    format("truetype");
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat Italic";
  src: url("../assets/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf")
    format("truetype");
  font-weight: 100 900;
  font-style: normal;
}

//Maison

@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/Maison/Maison Neue Light.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/Maison/Maison Neue Medium.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/Maison/Maison Neue Book.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/Maison/Maison Neue Bold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}


//Nexa

@font-face {
  font-family: "Nexa";
  src: url("../assets/fonts/Nexa/Nexa-Light.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("../assets/fonts/Nexa/Nexa-Book.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("../assets/fonts/Nexa/Nexa-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("../assets/fonts/Nexa/Nexa-Heavy.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("../assets/fonts/Nexa/Nexa-XBold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}


// Porlane

@font-face {
  font-family: "Porlane";
  src: url("../assets/fonts/Porlane/Porlane.ttf")
    format("truetype");
  font-style: normal;
}

// Helvetica 

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/Helvetica/HelveticaNeue.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: 'Helvetica Neue';
//   src: url('../assets/fonts/Helvetica/HelveticaNeue-Bold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/Helvetica/HelveticaNeue-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/Helvetica/HelveticaNeue-CondensedBlack.ttf') format('truetype');
  font-weight: 900; /* Ultra-bold */
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/Helvetica/HelveticaNeue-Bold.ttf') format('truetype');
  font-weight: 700; /* Extra-bold */
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/Helvetica/HelveticaNeue-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/Helvetica/HelveticaNeue-Light.ttf') format('truetype');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/Helvetica/HelveticaNeue-LightItalic.ttf') format('truetype');
  font-weight: 300; /* Light */
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/Helvetica/HelveticaNeue-Medium.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/Helvetica/HelveticaNeue-MediumItalic.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/Helvetica/HelveticaNeue-Thin.ttf') format('truetype');
  font-weight: 100; /* Thin */
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/Helvetica/HelveticaNeue-ThinItalic.ttf') format('truetype');
  font-weight: 100; /* Thin */
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/Helvetica/HelveticaNeue-UltraLight.ttf') format('truetype');
  font-weight: 200; /* Ultra-light */
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/Helvetica/HelveticaNeue-UltraLightItalic.ttf') format('truetype');
  font-weight: 200; /* Ultra-light */
  font-style: italic;
}


//Space Mono

@font-face {
  font-family: "Space Mono";
  src: url("../assets/fonts/SpaceMono/SpaceMono-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Space Mono";
  src: url("../assets/fonts/SpaceMono/SpaceMono-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Space Mono";
  src: url("../assets/fonts/SpaceMono/SpaceMono-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Space Mono";
  src: url("../assets/fonts/SpaceMono/SpaceMono-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}